<style>
  footer {
    margin-top: 15px;
    padding: 14px 15px 18px 15px;
    border-top: 1px solid #eee;
    text-align: center;
  }
</style>

<footer>
  ©
  <a href="https://github.com/julienpa" target="_blank" rel="noopener noreferrer">Julienpa</a>
  •
  Powered by
  <a href="https://svelte.dev/" target="_blank" rel="noopener noreferrer">Svelte</a>,
  <a href="https://firebase.google.com" target="_blank" rel="noopener noreferrer">Firebase</a>
  and
  <a href="https://www.algolia.com" target="_blank" rel="noopener noreferrer">Algolia</a>
</footer>
